import React from "react";
import Layout from "../../../components/layout/Layout";
import { useStaticQuery, graphql, Link } from "gatsby";
import ImageSection from "../../../components/ui/sections/ImageSection";
import TwoColSection from "../../../components/ui/sections/TwoColSection";
import Contact from "../../../components/global/Contact";

const StronyWWWPage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(relativePath: { eq: "working.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 640)
        }
      }
    }
  `);

  // const pros = [
  //   {
  //     title: "Mobile-first",
  //     description:
  //       "Nasze strony doskonale radzą sobie z urządzeniami mobilnymi. Wiemy, jak ważne jest to zarówno dla klientów, jak i wyszukiwarek.",
  //     icon: "ti-mobile",
  //   },
  //   {
  //     title: "Design",
  //     description:
  //       "Stawiamy na doskonałe odczucia przy obcowaniu z naszymi produktami. Wierzymy w prostotę, ciekawy design i użyteczność.",
  //     icon: "ti-layout",
  //   },
  //   {
  //     title: "Wydajność i analityka",
  //     description:
  //       "Przed wypuszczeniem strony do sieci sprawdzamy jej optymalizację i wydajność. Mierzymy Twoją stronę, aby wiedzieć gdzie i co możemy poprawić.",
  //     icon: "ti-bar-chart",
  //   },
  //   {
  //     title: "Uporządkowane zarządzanie",
  //     description:
  //       "Z nami dokładnie wiesz co, kiedy i za ile. Stawiamy na porządek w projekcie, zwinne zarządzanie i procedury.",
  //     icon: "ti-calendar",
  //   },
  // ];

  // const process = [
  //   {
  //     title: "Koncept strony",
  //     description:
  //       "Wspólnie myślimy o przeznaczeniu, głównych celach oraz funkcjonanościach strony internetowej. Tworzymy plan działania i zakładamy system zarządzania zadaniami, żeby ten plan spełnić.",
  //   },
  //   {
  //     title: "Podpisanie e-umowy",
  //     description:
  //       "Lubimy ekologię, więc nie marnujemy papieru na umowy. Do rozpoczęcia współpracy wystarczy kliknięcie linku w mailu potwierdzające chęć zaakceptowania umowy.",
  //   },
  //   {
  //     title: "Projektowanie szablonów",
  //     description:
  //       "Przenosimy nasze ustalenia z głowy do programów graficznych i projekujemy design widoków strony. Powstaje design interfejsu oraz koncepcje ułatwiające klientowi obcowanie z naszym produktem.",
  //   },
  //   {
  //     title: "Programowanie",
  //     description:
  //       "Mieliśmy czas zastanowić się jaka technologia i rozwiązanie będzie optymalne dla Naszej koncepcji. Czas więc wytoczyć działa i kodujemy.",
  //   },
  //   {
  //     title: "Przedstawienie wersji MVP",
  //     description:
  //       "Przez cały proces developmentu aktualna wersja produktu będzie dostępna dla Ciebie do wglądu. Szybciej więc zbierzemy feedback dotyczący działania poszczególnych funkcjonalności.",
  //   },
  //   {
  //     title: "Testowanie",
  //     description:
  //       "Przez cały okres developmentu oraz przed wdrożeniem, strona jest przez nas dokładnie testowana.",
  //   },
  //   {
  //     title: "Procedura przedwdrożeniowa",
  //     description:
  //       "Przed wdrożeniem strony należy podpiąć do niej narzędzia analityczne, upewnić się, że przez Google nie będą indeksowane żadne niechciane treści i wiele innych kwestii, które mamy spisane.",
  //   },
  //   {
  //     title: "Wdrożenie",
  //     description:
  //       "Proces deploymentu strony na serwerze z wykorzystaniem naszych narzędzi jest szybki i sprawny.",
  //   },
  //   {
  //     title: "Utrzymanie strony",
  //     description:
  //       "Przez cały proces dążymy do tego, żebyś był zadowolony z naszych usług i z chęcią kontynuwał współpracę przy utrzymaniu i rozwijaniu Twojej strony www.",
  //   },
  // ];

  return (
    <Layout pageTitle="Strony WWW">
      <section className="container mx-auto px-5">
        <ImageSection
          subtitle="Tworzymy i kreujemy wspólnie"
          title="Technologia jest po naszej stronie"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            W dzisiejszych czasach firma nie może funkcjonować i rozwijać się
            bez kreowania wizerunku w sieci. Oczywistym środkiem wyrazu w takim
            świecie jest strona internetowa, która powinna oddawać osobowość
            marki.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Strona internetowa powinna mówić w Twoim języku, być spójna i
            doskonale dopasowana. To przecież fundament Twojego e-biznesu, który
            musi utrzymać resztę.
          </p>
        </ImageSection>
      </section>

      <TwoColSection title="Tworzymy strony internetowe, które konwertują">
        <p>
          Strona internetowa, aplikacja webowa, portal mogą być dobre i złe,
          szybkie i wolne, zoptymalizowe pod kątem SEO oraz nieoptymalne. Mogą
          mieć dziury bezpieczeństwa lub być ich w znacznym stopniu pozbawione.
          Mogą umożliwiać skalowanie i dodawanie nowych funkcjonalności lub
          zależeć jedynie od dostępnych pluginów. Mogą być zaprojektowane tak,
          aby wciągać w interakcję lub odrzucać od szukania na stronie choćby
          możliwości
          <Link to="/kontakt"> kontaktu.</Link>
        </p>
      </TwoColSection>

      <TwoColSection title="Nowoczesne strony internetowe">
        <p>
          Wyobraź sobie stronę internetową, która pozwala Ci łatwo zarządzać
          wszystkimi treściami w jednym miejscu. Dane będą dostępne zarówno na
          stronie webowej, jak i w aplikacji mobilnej. Wyobraź sobie stronę
          internetową, która nie jest hakowana raz do roku, jej backup jest
          robiony systematycznie, a wykorzystane technologie są nowoczesne.
          Wyobraź sobie stronę internetową, która ładuje się błyskawicznie, nie
          jest obciążona niepotrzebnymi skryptami, a doświadczenia użytkownika
          płynące z jej używania są pozytywne. Następnie{" "}
          <Link to="/kontakt">zgłoś się do nas</Link>, my taką stronę
          zaprojektujemy, przygotujemy i utrzymamy, tak żeby wyobraźnię
          przenieść do internetu.
        </p>
      </TwoColSection>

      <TwoColSection title="Nasze mocne strony" background="gray">
        <h3 className="text-black text-2xl font-semibold mb-3">
          Korzystając z naszych rozwiązań zyskasz:
        </h3>
        <ul className="mb-3 list-disc list-inside">
          <li>nowoczesny projekt graficzny</li>
          <li>intuicyjny interfejs użytkownika</li>
          <li>
            system zarządzania treścią dla strony internetowej i aplikacji
            mobilnej (Headless CMS)
          </li>
          <li>funkcjonalności pod Twoje wymagania</li>
          <li>optymalizację SEO</li>
          <li>optymalizację prędkości</li>
          <li>fachowe doradztwo</li>
        </ul>
        <h3 className="text-black text-2xl font-semibold mb-3">
          Nasze kompetencje:
        </h3>
        <ul className="list-disc list-inside">
          <li>nowoczesne technologie (React.js, Node.js)</li>
          <li>optymalizacja stron internetowych</li>
          <li>customowe szablony oraz moduły</li>
          <li>integracja z systemami zewnętrznymi</li>
          <li>strona internetowa w duchu mobile-first</li>
          <li>projektowania doświadczeń związanych ze stroną internetową</li>
          <li>obsługa i rozwój stron www</li>
        </ul>
      </TwoColSection>
      <Contact />
    </Layout>
  );
};

export default StronyWWWPage;
