import React from "react";

const TwoColSection = ({ title, children, background = "light" }) => {
  return (
    <section
      className={`${background === "light" ? "bg-white" : "bg-gray-100"}`}
    >
      <div className="container mx-auto px-5 py-6 md:py-10">
        <div className="flex flex-wrap md:flex-nowrap md:space-x-10">
          <div className="md:w-2/6">
            <h2 className="text-2xl font-semibold mb-3">{title}</h2>
          </div>
          <div className="md:w-4/6 text-gray-500">{children}</div>
        </div>
      </div>
    </section>
  );
};

export default TwoColSection;
